.changePinBody {
  width: 100%;
  min-height: 450px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(100, 100, 100, 0.1);
  line-height: 450px;
}

.changePinForm, .errorMessage {
  text-align: center;
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
  padding-left: 20px;
  padding-right: 20px;
}

.changePin {
  width: 94%;
  padding: 20px 3%;
  color: rgb(53, 67, 80);
}

.changePinForm > .intro {
  font-weight: 900;
  font-size: 1.4em;
  margin-bottom: 0;
  margin-top: 0;
}

.changePinForm > .desc {
  font-weight: 400;
  font-size: 0.80em;
  line-height: 1.5em;
}
