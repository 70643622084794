/*.household {*/
/*  width: 94%;*/
/*  padding: 20px 3%;*/
/*  color: rgb(53, 67, 80);*/
/*}*/

/*.householdBody {*/
/*  width: 100%;*/
/*  min-height: 450px;*/
/*  background-color: rgb(255, 255, 255);*/
/*  border-radius: 10px;*/
/*  box-shadow: 0 0 6px 0 rgba(100, 100, 100, 0.1);*/
/*}*/

/*.householdBody > header, .householdBody > footer {*/
/*  padding: 30px;*/
/*}*/

/*.householdBody > header {*/
/*  text-align: center;*/
/*}*/

/*.householdBody > header > .intro {*/
/*  font-weight: 900;*/
/*  font-size: 1.4em;*/
/*  margin-bottom: 0;*/
/*}*/

/*.householdBody > header > .desc {*/
/*  font-weight: 400;*/
/*  font-size: 0.80em;*/
/*  line-height: 1.5em;*/
/*}*/

/*.actionTag.primary {*/
/*  color: #57C58B;*/
/*  !*padding-right: 20px;*!*/
/*}*/

/*.actionTag.pending {*/
/*  color: #EEC248;*/
/*  !*padding-right: 5px;*!*/
/*}*/

/*.actionTag.cancel {*/
/*  padding-right: 5px;*/
/*}*/

/*.footerDesc {*/
/*  font-size: 0.8em;*/
/*  margin-bottom: 0;*/
/*}*/

/*.footerError {*/
/*  font-size: 0.75em;*/
/*  color: rgb(255, 72, 72);*/
/*}*/



/*.homeIcon {*/
/*  padding-top: 30px;*/
/*}*/