.confirmInviteBody {
  width: 100%;
  min-height: 450px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(100, 100, 100, 0.1);
}

.confirmMessage, .errorMessage {
  text-align: center;
  padding: 20px;
}